export default [
  {
    path: '/Products',
    name: 'Products',
    component: () => import('@/views/Order/Shop/Index.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      title: 'Products',
      pageTitle: 'Products'
    }
  },
  {
    path: '/Product/:id',
    name: 'ProductDetail',
    component: () => import('@/views/Order/Product/Index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      title: 'Product',
      pageTitle: 'Product',
      breadcrumb: [
        {
          text: 'Products',
          to: { name: 'Products' }
        },
        {
          text: 'Product',
          active: true
        }
      ]
    }
  },
  {
    path: '/OfferRequest',
    name: 'OfferRequest',
    component: () => import('@/views/Offer/OfferRequest.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      title: 'OfferRequest',
      pageTitle: 'OfferRequest',
      breadcrumb: [
        {
          text: 'OfferRequest',
          active: true
        }
      ]
    }
  },
  {
    path: '/Offer/Thanks/:id',
    name: 'OfferThanks',
    component: () => import('@/views/Offer/OfferThanks.vue'),
    meta: {
      title: 'OfferThanks',
      layout: 'full'
    }
  }
]
