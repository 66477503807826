export const SET_BREADCRUMB = "setBreadcrumb";
export const PUSH_BREADCRUMB = "pushBreadcrumb";
export const POP_BREADCRUMB = "popBreadcrumb";
export const UPDATE_LOCALE = "updateLocale";

const state = {
  breadcrumbs: [],
  locale: localStorage.getItem("locale") ?? "tr-TR",
};

const getters = {
  breadcrumbs: (state) => state.breadcrumbs,
  getLocale: (state) => state.locale,
  defaultProductImage: (state, rootGetters) => {
    let skin = rootGetters["appConfig/getThemeSkin"];
    if (skin === 'light') {
      return require("@/assets/images/logo/product_image.png");
    } else {
      return require("@/assets/images/logo/product_image_dark.png");
    }
  },
};

const actions = {};

const mutations = {
  [SET_BREADCRUMB](state, payload) {
    state.breadcrumbs = [];
    if (payload?.breadcrumb?.length) {
      state.breadcrumbs = [...payload.breadcrumb];
    }
  },
  [PUSH_BREADCRUMB](state, payload) {
    state.breadcrumbs.push({
      text: payload,
      active: true,
      notTranslate: true,
    });
  },
  [POP_BREADCRUMB](state) {
    state.breadcrumbs.pop();
  },

  [UPDATE_LOCALE](state, payload) {
    state.locale = payload;
    localStorage.setItem("locale", payload);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
