import Vue from "vue";

import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/toastification";
import "@/libs/sweet-alerts";

// BSV Plugin Registration
Vue.use(ToastPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import fontawesome
require("@/assets/fontawesome/css/all.min.css");
require("@/assets/fontawesome/js/all");

//vee-validate wi18n
import "@/assets/js/functions/vee-validate.settings";

import "@/libs/portal-vue";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
