import axiosIns from '@/libs/axios'
import { SET_NOTIFICATION } from './notification-service'

//Actions
export const OFFER_REQUEST = 'offerRequest'

//Mutations
export const ADD_OFFER = 'addOffer'
export const REMOVE_OFFER = 'removeOffer'
export const CLEAR_OFFER = 'clearOffer'
export const UPDATE_OFFER = 'updateOffer'

const state = {
  offerList: 'offerList' in localStorage ? JSON.parse(localStorage.getItem('offerList')) : [],
  offererDetail: {
    name: undefined,
    surname: undefined,
    phone: undefined,
    email: undefined,
    company: undefined,
    detail: undefined
  }
}

const getters = {
  offerList: state => state.offerList,
  offererDetail: state => state.offererDetail
}

const actions = {
  [OFFER_REQUEST]({ commit, state }) {
    return new Promise((resolve, reject) => {
      let body = {
        name: state.offererDetail.name,
        surname: state.offererDetail.surname,
        company_name: state.offererDetail.company,
        email: state.offererDetail.email,
        description: state.offererDetail.detail,
        gsm_number: state.offererDetail.phone,
        offer_details: state.offerList.map(x => ({ product_id: x.product_id, quantity: +x.quantity, unit_id: x.unit_id, price: 0, currency_id: x.currency_id }))
      }
      axiosIns
        .post('Offer/OfferComplete', body)
        .then(res => {
          commit(SET_NOTIFICATION, res.data)
          if (res.data.status) {
            commit(CLEAR_OFFER)
            resolve({ status: true, offer_no: res.data.data })
          } else {
            resolve({ status: false })
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
          reject()
        })
    })
  }
}

const mutations = {
  [ADD_OFFER](state, payload) {
    state.offerList.push(payload)
    localStorage.setItem('offerList', JSON.stringify(state.offerList))
  },
  [REMOVE_OFFER](state, payload) {
    state.offerList.forEach((item, index) => {
      if (item.product_id === payload.product_id && item.unit_id === payload.unit_id) {
        state.offerList.splice(index, 1)
      }
    })
    localStorage.setItem('offerList', JSON.stringify(state.offerList))
  },
  [CLEAR_OFFER](state) {
    state.offerList = []
    localStorage.setItem('offerList', JSON.stringify(state.offerList))
  },
  [UPDATE_OFFER](state, payload) {
    state.offerList.forEach(item => {
      if (item.product_id === payload.product_id && item.unit_id === payload.unit_id) {
        item.quantity = payload.quantity
      }
    })
    localStorage.setItem('offerList', JSON.stringify(state.offerList))
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
