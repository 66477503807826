import Vue from "vue";
import VueI18n from "vue-i18n";
import tr from "vee-validate/dist/locale/tr.json";
import en from "vee-validate/dist/locale/en.json";

Vue.use(VueI18n);
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
      switch (locale) {
        case "tr-TR":
          messages[locale].validation = tr.messages;
          break;
        default:
          messages[locale].validation = en.messages;
          break;
      }
    }
  });
  return messages;
}
export default new VueI18n({
  locale: "tr-TR",
  fallbackLocale: "tr-TR",
  messages: loadLocaleMessages(),
});
