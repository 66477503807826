import Vue from 'vue'
import VueRouter from 'vue-router'
import shop from './routes/shop'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home/Home.vue'),
      meta: {
        title: 'Home',
        layout: 'horizontal'
      }
    },
    {
      path: '/PageNotFound',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        title: 'Page Not Found',
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: to => {
        return { name: 'error-404' }
      }
    },
    {
      path: '/ComingSoon',
      name: 'coming-soon',
      component: () => import('@/views/error/ComingSoon.vue'),
      meta: {
        title: 'Coming Soon',
        layout: 'full'
      }
    },
    ...shop
  ]
})

router.afterEach(to => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
