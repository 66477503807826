import axiosIns from '@/libs/axios'

//Actions
export const GET_ALL_PRODUCTS = 'getAllProducts'
export const GET_PRODUCT = 'getProduct'
export const GET_ALL_PRODUCT_GROUP = 'getAllProductGroup'
export const GET_ALL_BRAND = 'getAllBrand'
export const CHANGE_SHOP_PAGE_EVENT = 'changeShopPageEvent'

//Mutations
export const SET_DEFAULT_SHOP = 'setDefaultShop'
export const SET_ALL_PRODUCTS = 'setAllProducts'
export const SET_ALL_PRODUCT_GROUP = 'setAllProductGroup'
export const SET_ALL_BRAND = 'setAllBrand'
export const SELECT_CATEGORY = 'selectCategory'
export const SELECT_BRAND = 'selectBrand'
export const TYPE_SEARCH = 'typeSearch'
export const TYPE_BRAND_SEARCH = 'typeBrandSearch'
export const TYPE_CATEGORY_SEARCH = 'typeCategorySearch'
export const CHANGE_SHOP_PAGE = 'changeShopPage'
export const CLEAR_SHOP_FILTERS = 'clearShopFilters'
export const PUSH_PRODUCT_IMAGE = 'pushProductImage'
export const CHANGE_PER_PAGE = 'changePerPage'

const state = {
  allProducts: [],
  images: [],
  categories: [],
  brands: [],
  filters: {
    search: '',
    category: [],
    categorySearch: '',
    brand: [],
    brandSearch: '',
    page: 1,
    perPage: 24,
    totalCount: 0
  }
}

const getters = {
  rawAllProducts: state => state.allProducts,
  filteredProducts: (state, rootGetters) => {
    let temp = [...state.allProducts]
    //Eğer Ürün Grubu Seçilmişse
    if (state.filters.category.length > 0) {
      temp = temp.filter(x => state.filters.category.includes(x.product_group_id))
    }
    //Eğer Marka Seçilmişse
    if (state.filters.brand.length > 0) {
      temp = temp.filter(x => state.filters.brand.includes(x.brand_id))
    }
    //Eğer Arama Yapılmışsa
    if (state.filters.search !== '') {
      let search = state.filters.search.toLowerCase()
      temp = temp.filter(
        x =>
          x.brand_name?.toLowerCase().includes(search) ||
          x.product_name?.toLowerCase().includes(search) ||
          x.orginal_code?.toLowerCase().includes(search) ||
          (x.product_description != null && x.product_description?.toLowerCase().includes(search)) ||
          x.product_code?.toLowerCase().includes(search)
      )
    }
    state.filters.totalCount = temp.length
    temp = temp.sort((x, y) => {
      let xO = (+x.order_no ?? 0) * (+x.product_group_id ?? 0)
      let yO = (+y.order_no ?? 0) * (+y.product_group_id ?? 0)
      return xO > yO ? 1 : xO < yO ? -1 : 0
    })
    temp = temp.slice((state.filters.page - 1) * state.filters.perPage, state.filters.page * state.filters.perPage)
    return temp
  },
  filteredProductsCount: state => state.filters.totalCount,
  shopSearch: state => state.filters.search,
  selectedCategory: state => state.filters.category,
  categorySearch: state => state.filters.categorySearch,
  selectedBrand: state => state.filters.brand,
  brandSearch: state => state.filters.brandSearch,
  shopCategories: (state, rootGetters) => {
    if (state.filters.categorySearch !== '') {
      let temp = state.filters.categorySearch.toLocaleLowerCase(rootGetters.getLocale)

      state.categories.forEach(item => {
        item.show = false
        item.open = false
        item.childrens.forEach(child => {
          child.show = false
        })
      })

      state.categories
        .filter(x => x.childrens.findIndex(y => y.text.toLocaleLowerCase(rootGetters.getLocale).includes(temp)) !== -1)
        .forEach(item => {
          item.show = true
          item.open = true
          item.showedChild = 0
          item.childrens
            .filter(x => x.text.toLocaleLowerCase(rootGetters.getLocale).includes(temp))
            .forEach(child => {
              child.show = true
              item.showedChild++
            })
        })
    } else {
      state.categories.forEach(item => {
        item.show = true
        item.open = false
        item.showedChild = 0
        item.childrens.forEach(child => {
          child.show = true
          item.showedChild++
        })
      })
    }

    return state.categories
  },
  shopBrands: (state, rootGetters) => {
    state.brands = state.brands.sort((x, y) => x.text.localeCompare(y.text, rootGetters.getLocale))

    if (state.filters.brandSearch !== '') {
      let temp = state.filters.brandSearch.toLocaleLowerCase(rootGetters.getLocale)
      state.brands.forEach(item => {
        item.show = item.text.toLocaleLowerCase(rootGetters.getLocale).includes(temp)
      })
    } else {
      state.brands.forEach(item => {
        item.show = true
      })
    }

    let filteredBrands = []
    let temp = state.allProducts
    if (state.filters.category.length > 0) {
      temp = temp.filter(x => state.filters.category.includes(x.product_group_id))
    }
    temp.forEach(item => {
      if (!filteredBrands.includes(item.brand_id)) {
        filteredBrands.push(item.brand_id)
      }
    })

    state.brands.forEach(item => {
      if (!filteredBrands.includes(item.value)) {
        item.show = false
      }
    })
    return state.brands
  },
  shopPage: state => state.filters.page,
  shopPerPage: state => state.filters.perPage,
  productImages: state => state.images,
  shopFilters: state => state.filters,
  brandLogo: (state, rootGetters) => {
    let skin = rootGetters['appConfig/getThemeSkin']
    switch (skin) {
      case 'dark':
        return rootGetters.brandLogoDark
      default:
        return rootGetters.brandLogoLight
    }
  },
  brandLogoDark: state => {
    return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=1697`
  },
  brandLogoLight: state => {
    return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=1696`
  }
}

const actions = {
  [GET_ALL_PRODUCTS]({ commit, rootGetters }) {
    return new Promise(resolve => {
      commit(SET_DEFAULT_SHOP)
      axiosIns
        .get('Product/GetAllProductSHOP')
        .then(response => {
          commit(SET_ALL_PRODUCTS, { products: response.data.data, offerList: rootGetters.offerList })
          resolve()
        })
        .catch(err => {})
    })
  },
  [GET_PRODUCT]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Product/GetByIdProductSHOP?product_id=${payload}`)
        .then(response => {
          if (response.status) {
            resolve(response.data.data)
          } else {
            reject()
          }
        })
        .catch(() => {})
    })
  },
  [GET_ALL_PRODUCT_GROUP]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Product/GetAllProductGroupSHOP')
        .then(response => {
          commit(SET_ALL_PRODUCT_GROUP, response.data.data)
          resolve()
        })
        .catch(() => {})
    })
  },
  [GET_ALL_BRAND]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Product/GetAllProductBrandSHOP')
        .then(response => {
          commit(SET_ALL_BRAND, response.data.data)
          resolve()
        })
        .catch(() => {})
    })
  },
  [CHANGE_SHOP_PAGE_EVENT]({ dispatch, commit, state, getters }, payload) {
    return new Promise(resolve => {
      commit(CHANGE_SHOP_PAGE, payload)
      resolve()
    })
  }
}

const mutations = {
  [SET_DEFAULT_SHOP](state) {
    state.allProducts = []
    state.brands = []
    state.categories = []
    state.filters = {
      search: '',
      category: [],
      categorySearch: '',
      brand: [],
      brandSearch: '',
      page: 1,
      perPage: 24,
      totalCount: 0
    }
  },
  [SET_ALL_PRODUCTS](state, payload) {
    state.allProducts = []
    payload.products?.forEach(item => {
      let temp = item.compatible_brands.split(',')
      let compatible_brands = []
      temp.forEach(i => {
        i.split('-').forEach(j => {
          compatible_brands.push(j)
        })
      })
      item.compatible_brands = compatible_brands
      item.offerButtonState = false

      item.tra_unit_id = item.sales_unit_id
      item.units = JSON.parse(item.units ?? '[]')
      if (typeof item.units === 'string') {
        item.units = []
      }

      item.units.forEach(unit => {
        let q = payload.offerList?.find(x => x.product_id === item.product_id && x.unit_id === unit.unit2_id)?.quantity
        unit.quantity = q ?? 1
      })
    })
    state.allProducts = payload.products
  },
  [SET_ALL_PRODUCT_GROUP](state, payload) {
    state.categories = []
    payload
      .filter(x => !x.parent_group_id)
      .forEach(parent => {
        state.categories.push({
          id: parent.product_group_id,
          name: parent.product_group_name,
          childrens: []
        })
      })
    payload
      .filter(x => x.parent_group_id)
      .forEach(item => {
        let parent = state.categories.find(x => x.id === item.parent_group_id)
        if (parent) {
          parent.childrens.push({
            value: item.product_group_id,
            text: item.product_group_name
          })
        }
      })
  },
  [SET_ALL_BRAND](state, payload) {
    state.brands = []
    payload.forEach(item => {
      state.brands.push({
        text: item.brand_name,
        value: item.brand_id
      })
    })
  },
  [SELECT_CATEGORY](state, payload) {
    state.filters.category = payload
    state.filters.brand = []
  },
  [SELECT_BRAND](state, payload) {
    state.filters.brand = payload
  },
  [TYPE_SEARCH](state, payload) {
    state.filters.search = payload
  },
  [CHANGE_SHOP_PAGE](state, payload) {
    state.filters.page = payload
  },
  [TYPE_BRAND_SEARCH](state, payload) {
    state.filters.brandSearch = payload
  },
  [TYPE_CATEGORY_SEARCH](state, payload) {
    state.filters.categorySearch = payload
  },
  [CLEAR_SHOP_FILTERS](state) {
    state.filters.page = 1
    state.filters.category = []
    state.filters.brand = []

    state.brands.forEach(item => {
      item.show = true
    })
  },
  [PUSH_PRODUCT_IMAGE](state, payload) {
    if (payload.images !== null && payload.images !== undefined) {
      let images = JSON.parse(payload.images)
      if (Array.isArray(images)) {
        state.images.push({
          id: payload.product_id,
          images
        })
      } else {
        state.images.push({
          id: payload.product_id,
          images: []
        })
      }
    } else {
      state.images.push({
        id: payload.product_id,
        images: []
      })
    }
  },
  [CHANGE_PER_PAGE](state, payload) {
    state.filters.perPage = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
