//Actions

//Mutations
export const SET_NOTIFICATION = 'setNotification'

const state = {
  notification: {}
}

const getters = {
  getNotification: state => state.notification
}

const actions = {}

const mutations = {
  [SET_NOTIFICATION](state, payload = {}) {
    let notification = {
      show: payload?.show ?? true,
      type: payload?.type ?? 2, //1 => Notification - 2 => Swal
      title: payload?.title ?? i18n?.t('notifications.connection_problem_title'),
      text: payload?.message ?? i18n?.t('notifications.connection_problem_text'),
      html: payload?.html ?? undefined,
      icon: payload?.icon ?? 'warning', // type = 1 ise variant kullan
      variant: payload?.variant ?? undefined
    }

    if ('status' in payload && payload?.type === undefined) {
      if (payload.status) {
        notification.type = 1
        notification.variant = 'success'
        notification.icon = 'CheckCircleIcon'
      } else {
        notification.icon = 'error'
      }
      notification.title = payload.title
    }
    state.notification = notification
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
