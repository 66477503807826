<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import Toastification from '@/layouts/components/Toastification.vue'
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import { SET_BREADCRUMB } from './store/services/breadcrumb-service'
import store from './store'
import { mapGetters } from 'vuex'

const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutFull,
    Toastification,
    ScrollToTop
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters(['getNotification']),
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

    // Set Layout Type From LocalStorage
    if (!('layoutType' in localStorage)) {
      localStorage.layoutType = 'horizontal'
    }

    new Promise(resolve => {
      this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', localStorage.layoutType)
      resolve()
    })
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 1250,
      transition: 'Vue-Toastification__fade'
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = `KombiKlimaParca - ${this.$t(to.meta.title)}`
        this.$store.commit(SET_BREADCRUMB, { ...to.meta })
      }
    },
    getNotification(notification) {
      if (notification.show) {
        //Eğer Toastr Göstermek İstiyorsak
        if (notification.type === 1) {
          this.$toast(
            {
              component: Toastification,
              props: {
                //title: this.$t(`notifications.${notification.title}`),
                //text: this.$t(`notifications.${notification.text}`),
                title: notification.title,
                text: notification.text,
                icon: notification.icon,
                variant: notification.variant
              }
            },
            {
              position: 'bottom-left'
            }
          )
        }
        //Eğer Swal Göstermek İstiyorsak
        else {
          this.$swal({
            title: notification.title,
            text: notification.text,
            html: notification.html,
            icon: notification.icon,
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            confirmButtonText: this.$t('notifications.confirm_button_text'),
            buttonsStyling: false
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
